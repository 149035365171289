<template>
  <div class="container">
    <h3>
      <a href="">
        企业荣誉
      </a>
    </h3>
    <div class="content">
      <ul class="con-zz">
        <li v-for="(item,index) in zzArr" class="item" :key="index">
          <img :src="item.src" alt="">
        </li>
      </ul>
      <ul class="con-cc">
        <li v-for="(item,index) in ssArr" class="item" :key="index">
          <img :src="item.src" alt="">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      zzArr: [
        {
          src: require("../assets/zz/h1.png")
        },
        {
          src: require("../assets/zz/h2.png")
        },
        {
          src: require("../assets/zz/h3.png")
        },
        {
          src: require("../assets/zz/h4.png")
        },
        {
          src: require("../assets/zz/h5.png")
        },
        {
          src: require("../assets/zz/h6.png")
        }
      ],
      ssArr: [
        // {
        //   src: require("../assets/zz/s1.png")
        // },
        // {
        //   src: require("../assets/zz/s4.png")
        // },
        {
          src: require("../assets/zz/s2.png")
        },
        {
          src: require("../assets/zz/s3.png")
        }
      ]
    }
  },
  components: {

  }
}
</script>

<style scoped lang="scss">
.container {
  width: 1170px;
  background-color: #fff;
  margin: 20px auto;
  padding: 20px;

  h3 {
    text-align: left;
    padding: 5px 90px;
    border-bottom: 1px dashed #ccc;
    a {
      color: #409eff;
      font-family: 'Open Sans';
      font-weight: 400;
      font-size: 27px;
      letter-spacing: 0px;
      line-height: 35px;
      margin: 0 0 60px;
      word-wrap: break-word;
    }
  }

  .content {
    border-bottom: 1px dashed #ccc;
    padding: 0 0 40px;
    .con-zz {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      .item {
        border: 1px solid #9ba1a0;
        padding: 10px;
        margin-right: 20px;
        margin-bottom: 20px;
        width: 450px;
        img {
          width: 100%;
        }
      }
    }

    .con-cc {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      .item {
        border: 1px solid #9ba1a0;
        padding: 10px;
        margin-right: 20px;
        margin-bottom: 20px;
        width: 450px;
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
